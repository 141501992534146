//
//
//
//
//
//

export default {
  name: 'Customize',
  components: {},
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
