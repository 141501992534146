//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Error',
  fetch() {

  },
  data() {
    return {
      countDownNumber: 5,
      timer: null,
    };
  },
  mounted() {
    this.countDown();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    countDown() {
      this.timer = setInterval(() => {
        this.countDownNumber -= 1;
        if (this.countDownNumber === 0) {
          clearInterval(this.timer);
          this.$router.push('/');
        }
      }, 1000);
    }
  }
};
