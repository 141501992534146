import { render, staticRenderFns } from "./secondPageUser.vue?vue&type=template&id=45eb662e&scoped=true&"
import script from "./secondPageUser.vue?vue&type=script&lang=js&"
export * from "./secondPageUser.vue?vue&type=script&lang=js&"
import style0 from "./secondPageUser.vue?vue&type=style&index=0&id=45eb662e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45eb662e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Shortcut: require('/root/workspace/ydw_b2c_pc_E5ea/components/shortcut.vue').default,Header: require('/root/workspace/ydw_b2c_pc_E5ea/components/header.vue').default,UserLeft: require('/root/workspace/ydw_b2c_pc_E5ea/components/user/userLeft.vue').default,Footer: require('/root/workspace/ydw_b2c_pc_E5ea/components/footer.vue').default})
