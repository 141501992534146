//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cartSize } from '~/api/home';
import { mapActions } from 'vuex';
export default {
  name: 'Shortcut',
  components: {},
  data() {
    return {
      qualificationsList: [     // 资质轮播
        // '互联网药品信息服务资格证书：(京)•经营性•2014•0011',
        '互联网药品信息服务资格证书：(浙)•经营性•2024•0259',
        '营业执照',
        // '（京）网械平台备字（2018）第00010号',
        '(浙)网药平台备字(2024)第000004-000号',
        // // '互联网药品交易资格证书：国 A20160008',
        // '食品经营许可证',
        '用户服务协议',
        '平台交易规则',
        '隐私权政策',
        '食品经营者审查登记规范',
        '医疗器械审查登记规范',
        '入驻平台企业核实登记制度'
      ],
      usertoken: null,
      userInfo: null,
      cartNumber: '0'
    };
  },
  watch: {},
  computed: {
    unreadMessage: {
      get() {
        return this.$store.state.message.unreadMessage;
      }
    },
  },
  created() {},
  mounted() {
    if (this.$store.getters.getUserToken()) {
      this.usertoken = this.$store.getters.getUserToken();
      this.userInfo = this.$store.getters.getUserInfo();
      this.getCartSize();
    }
  },
  methods: {
    ...mapActions(['setUserToken', 'setUserInfo']),
    // 安全退出
    logOut() {
      this.setUserToken('');
      this.setUserInfo(null);
      if (this.$route.path=='/') {
        this.$router.go(0);
      } else {
        this.$router.push({ path: '/' });
      }
    },
    getCartSize() {
      cartSize().then(data => {
        if (data.code === '0000') {
          this.$store.commit('home/addCart', data.data);
        }
      });
    },
    goLogin() {
      this.$router.push({ path: '/login' });
    },
    // 购物车/我的订单/用户中心 链接跳页
    goToPage(path) {
      if (this.usertoken) {
        console.log(typeof this.userInfo.mobile);
        if (this.userInfo.mobile) {
          this.$router.push({ path: path });
        } else {
          this.$router.push({ path: '/login/bindPhone' });
        }
      }

    },
  }
};
