import { render, staticRenderFns } from "./secondPage.vue?vue&type=template&id=1d688ef0&scoped=true&"
import script from "./secondPage.vue?vue&type=script&lang=js&"
export * from "./secondPage.vue?vue&type=script&lang=js&"
import style0 from "./secondPage.vue?vue&type=style&index=0&id=1d688ef0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d688ef0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Shortcut: require('/root/workspace/ydw_b2c_pc_E5ea/components/shortcut.vue').default,Header: require('/root/workspace/ydw_b2c_pc_E5ea/components/header.vue').default,Footer: require('/root/workspace/ydw_b2c_pc_E5ea/components/footer.vue').default})
