import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c5b96d50&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=c5b96d50&lang=scss&scoped=true&"
import style1 from "./default.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5b96d50",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Shortcut: require('/root/workspace/ydw_b2c_pc_E5ea/components/shortcut.vue').default,Header: require('/root/workspace/ydw_b2c_pc_E5ea/components/header.vue').default,Footer: require('/root/workspace/ydw_b2c_pc_E5ea/components/footer.vue').default})
