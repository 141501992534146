//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Shortcut from '../components/shortcut';
import Footer from '../components/footer';
import Header from '../components/header';
import UserLeft from '../components/user/userLeft';
export default {
  name: 'SecondPageUser',
  components: {
    Shortcut,
    Header,
    Footer,
    UserLeft,
  },
  data() {
    return {

    };
  },
  watch: {},
  created() {},
  mounted() {
  },
  methods: {}
};
