//
//
//
//
//
//
//
//
//
//
//
//
//

import Shortcut from '../components/shortcut';
import Footer from '../components/footer';
import Header from '../components/header';
export default {
  name: 'Main',
  components: {
    Shortcut,
    Header,
    Footer
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
