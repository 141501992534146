//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getUserLoginId } from '~/api/user';
import { mapActions } from 'vuex';
export default {
  name: 'UserLeft',
  data() {
    return {
      lineTop: 40,
      activePath: '/user/orderPage',
      userInfo: {}
    };
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        // 监听进入页面选中
        this.activePath = val.path;
        this.$nextTick(() => {
          if (process.browser) {
            this.lineTop = document.getElementsByClassName('active')[0].offsetTop;
          }
        });
      },
      immediate: true
    }
  },
  created() {},

  activated() {},
  computed: {
    unreadMessage: {
      get() {
        return this.$store.state.message.unreadMessage;
      }
    },
  },

  mounted() {
    this.getUserInfo();
    this.getNotReadMessageCountFn(); // 获取站内未读消息统计
  },

  methods: {
    changlinTope(event, path) {
      this.lineTop = event.currentTarget.offsetTop;
      this.activePath = path;
      this.$router.push({ path: path });
    },
    // 获取用户信息
    getUserInfo() {
      getUserLoginId().then(res => {
        if (res.code=='0000') {
          this.userInfo =  res.data;
        }
      });
    },
    ...mapActions('message', ['getNotReadMessageCountFn']),
  }
};

