//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { categoryList, recordText } from '~/api/home';
import { setCookie, getCookie, removeCookie } from '~/utils/storage';
export default {
  name: 'Header',
  components: {},
  fetch() {
    if (this.$route.name === '/' || this.$route.name === 'index') {
      this.isHome = true;
      this.showClassify = true;
      this.transverse = '1';
    } else {
      this.isHome = false;
      this.showClassify = false;
      if (this.$route.name !== 'transverse' && this.$route.name !== 'brand-brandList' &&
          this.$route.name !== 'transverse-goodShop' && this.$route.name !== 'transverse-articleMain') {
        this.transverse = 0;
      } else if (this.$route.name == 'transverse') {
        this.transverse = '5';
      } else if (this.$route.name == 'brand-brandList') {
        this.transverse = '4';
      } else if (this.$route.name == 'transverse-goodShop') {
        this.transverse = '3';
      } else if (this.$route.name == 'transverse-articleMain') {
        this.transverse = '2';
      }
    }
  },
  data() {
    return {
      classifyListChildren: [], // 全部分类二级菜单
      classifyListChildrenThree: [], // 全部分类三级菜单
      navList: [  // 横向导航
        {
          name: '首页',
          id: '1'
        },
        {
          name: '医药资讯',
          id: '2'
        },
        {
          name: '药兜好店',
          id: '3'
        },
        {
          name: '品牌馆',
          id: '4'
        }
      ],
      historySearchData: [],  // 历史搜索
      cartNumber: 8,
      inpVal: '',
      showSecondaryMenu: false, // 显示二级菜单
      showThreearyMenu: false, // 显示三级菜单
      isHome: true, // 是否是首页
      showClassify: true,
      showHistorySearch: false, // 显示历史搜索框
      transverse: 0,
      pagePath: '',
      oneClass: '', // 一级分类
      usertoken: '',
      userInfo: null
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(to, from) {
        this.pagePath = to.path;
        if (to.path === '/') {
          this.isHome = true;
          this.showClassify = true;
          this.transverse = '1';
        } else {
          this.isHome = false;
          this.showClassify = false;
          if (to.path != '/transverse' && to.path != '/brand/brandList' &&
          to.path != '/transverse/goodShop' && to.path != '/transverse/articleMain') {
            this.transverse = 0;
          } else if (to.path == '/transverse') {
            this.transverse = '5';
          } else if (to.path == '/brand/brandList') {
            this.transverse = '4';
          } else if (to.path == '/transverse/goodShop') {
            this.transverse = '3';
          } else if (to.path == '/transverse/articleMain') {
            this.transverse = '2';
          }

        }
        if (to.path=='/search') {
          if (this.$route.query.searchCondition) {
            this.inpVal =  this.$route.query.searchCondition;
          }
        } else {
          this.inpVal = '';
        }
      },
      immediate: true
    }
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    if (this.$store.getters.getUserToken()) {
      this.usertoken = this.$store.getters.getUserToken();
    }
    this.showClassify = true;
  },
  mounted() {
    document.addEventListener('click', (e) => {
      let inp = null;
      if (this.$refs.inputBox) {
        inp = this.$refs.inputBox.contains(e.target);
      }
      if (this.$refs.showHistoryBox) {
        let isSelf = this.$refs.showHistoryBox.contains(e.target);
        if (!isSelf && !inp) {
          this.showHistorySearch = false;
        }
      }
    });
    this.getCategoryList();
    this.getRecordText();
  },
  methods: {
    /**
     * 跳转到购物车页面
     */
    goToPage(path) {
      if (this.usertoken) {
        if (this.userInfo.mobile) {
          this.$router.push({ path: path });
        } else {
          this.$router.push({ path: '/login/bindPhone' });
        }
      }
    },
    /**
     * 跳到登录页面
     */
    goLogin() {
      this.$router.push({ path: '/login' });
    },
    /**
     * 获取全部分类
     */
    getCategoryList() {
      categoryList({
        recommendFlag: 1,
        shelfStatusFlag: 1
      }).then(data => {
        if (data.code === '0000') {
          this.$store.commit('home/setCategoryAll', data.data);
        }
      });
    },
    /**
     * 热门搜索列表
     */
    getRecordText() {
      recordText().then(data => {
        if (data.code=='0000') {
          this.$store.commit('home/setHotList', data.data);
        }
      });
    },
    /**
     * 二级分类点击
     */
    classItemTwo(item) {
      this.$router.push({ name: 'brand-brandProductsList', query: { categoryId: item.categoryId, parentClass: this.oneClass, childClass: item.categoryName }});
      this.showSecondaryMenu = false;
      this.showClassify = false;
    },
    /**
     * 三级分类点击
     */
    classItemThree(item) {
      this.$router.push({ name: 'brand-brandProductsList', query: { categoryId: item.categoryId, parentClass: this.oneClass, childClass: item.categoryName }});
      this.showThreearyMenu = false;
      this.showClassify = false;
    },
    getCookieMethod() {
      getCookie('isHome');
    },
    /**
     * 搜索按钮点击
     */
    searchButtonClick() {
      if (this.inpVal === '') {
        this.$message.error('请输入要搜索的商品');
        return false;
      }
      this.saveHistorySearch(this.inpVal);
      this.$router.push({ name: 'search', query: { searchCondition: this.inpVal }});
    },
    /**
     * 搜本店
     */
    searchOurShop() {
      if (this.inpVal === '') {
        this.$message.error('请输入要搜索的商品');
        return false;
      }
      this.saveHistorySearch(this.inpVal);
      this.$router.push({ name: 'shop', query: { partyId: this.$store.state.home.partyId, searchCondition: this.inpVal }});
    },
    /**
     * 保存历史搜索
     */
    saveHistorySearch(val) {
      if (!getCookie('historySearch')) {
        let historySearchArr = [];
        historySearchArr.unshift(val);
        setCookie('historySearch', JSON.stringify(historySearchArr));
      } else {
        let hArr = JSON.parse(getCookie('historySearch'));
        if (hArr.indexOf(val)===-1) {
          hArr.unshift(val);
          setCookie('historySearch', JSON.stringify(hArr));
        }
      }
    },
    /**
     * 输入框获取焦点
     */
    getFocus() {
      this.inpVal = '';
      if (getCookie('historySearch')) {
        this.showHistorySearch = true;
        this.historySearchData = JSON.parse(getCookie('historySearch'));
      }
    },
    /**
     * 热门搜索点击
     */
    quickSearch(dos) {
      this.inpVal = dos;
      this.saveHistorySearch(this.inpVal);
      this.$router.push({ name: 'search', query: { searchCondition: this.inpVal }});
    },
    /**
     * 历史搜索列表点击
     */
    historySearchCommit(text) {
      this.inpVal = text;
      this.showHistorySearch = false;
      this.$router.push({ name: 'search', query: { searchCondition: this.inpVal }});
    },
    /**
     * 清空历史记录
     */
    clearHistory() {
      removeCookie('historySearch');
      this.showHistorySearch = false;
      this.historySearchData = [];
    },
    /**
     * 全部分类鼠标移入事件
     */
    getMouseover(item, oneClass, lv) {
      console.log(item);
      if (lv==2) {
        this.classifyListChildren = [];
        if (oneClass) {
          this.oneClass = oneClass;
        }
        if (item) {
          this.classifyListChildren = item;
          // this.classifyListChildren = this.arrTrans(item, 9);
          console.log(this.classifyListChildren);
        }
        this.showSecondaryMenu = true;
        this.showThreearyMenu = false;
      }
      if (lv==3) {
        if (oneClass) {
          this.oneClass = oneClass;
        }
        this.classifyListChildrenThree = [];
        if (item) {

          this.classifyListChildrenThree = item;
          // this.classifyListChildrenThree = this.arrTrans(item, 9);
          this.showThreearyMenu = true;
        } else {
          this.showThreearyMenu = false;
        }

      }
      this.showClassify = true;
    },
    /**
     * 全部分类鼠标移出事件
     */
    setMouseover() {
      this.showSecondaryMenu = false;
      this.showThreearyMenu = false;
      if (!this.isHome) {
        this.showClassify = false;
      }
      this.classifyListChildren = [];
      this.classifyListChildrenThree = [];
    },
    /**
     * 横向导航点击
     */
    lateralNavigation(id) {
      this.clickIndex = id;
      this.transverse = id;
      switch (id) {
      case '1' :
        this.$router.push('/');
        break;
      case '2':
        this.$router.push('/transverse/articleMain');
        break;
      case '3' :
        this.$router.push('/transverse/goodShop');
        break;
      case '4':
        this.$router.push('/brand/brandList');
        break;
      case '5':
        this.$router.push('/transverse');
        break;
      }
    },
    /**
     * 处理分类二级数据
     */
    arrTrans(arr, num) {
      const iconsArr = []; // 声明数组
      arr.forEach((item, index) => {
        const page = Math.floor(index / num); // 计算该元素为第几个素组内
        if (!iconsArr[page]) { // 判断是否存在
          iconsArr[page] = [];
        }
        iconsArr[page].push(item);
      });
      return iconsArr;
    }
  }
};
